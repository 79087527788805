import React from "react"

// Styles
import styles from "../../styles/components/about/header.module.scss"

export default function header() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1 className={styles.title}>About Us</h1>
        <p className={styles.description}>
          We are on a mission to provide the highest efficiency and lowest cost per
          watt of any solar provider within the country
        </p>
      </div>
    </div>
  )
}
