import React from "react"
import Title from "../smallTitle"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Articles from "../articles"
import Follow from "../follow"

// Styles
import styles from "../../styles/components/about/aboutSunterra.module.scss"

export default function AboutSunterra() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "about2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section >
    
        <div className={styles.wrapper}>
          <Title
            text="What We Do"
            subtitle="Our vision is to be the most trusted solar
            installer in Sri Lanka with the largest network of
            operations Island-wide"
          />
          <div className={styles.grid}>
            <div className={styles.imgWrapper}>
              <Img
                fluid={data.file.childImageSharp.fluid}
                objectPosition={"100% 100%"}
              />
              <div className={styles.content}>
                <div className={styles.text}>
                  <p>
                    SunTerra installs residential and commercial solar system
                    across Sri Lanka. Our central goal is to be the most minimal
                    cost control maker on the planet. We have accomplished a
                    generous decrease in the “cost of solar” through various
                    forms of increasing productivity, management systems and
                    long terms contracts with suppliers.
                  </p>
                  <p>
                    We have been instrumental in establishing Sri Lanka’s first
                    Third Party Owned Solar Contracts back in 2017. In such
                    contracts, we ensure the power generation of our solar power
                    plants to our clients.
                  </p>
                  <p>
                    Innovation - Innovation In a range of tasks handled in a
                    solar installation has enabled us to enhance the quality of
                    work carried out by our company and also aggregated the
                    solar tasks cost and yield. We effortlessly scale our tasks
                    without critical increments to headcount enabling us to
                    develop a few undertaking in parallel without bargaining on
                    effectiveness.
                  </p>
                  <p>
                    Efficiency - All our solar system designing and
                    administration tasks are created on systematic procedures.
                    These initiatives give the advantage of fundamentally
                    lessening the time in takes to design, install and
                    commission projects at a shorter period of time.
                  </p>
                  <p>
                    Research &amp; Development - We pride our selves with the
                    time spent of R&amp;D on the all the solar products used in
                    an installation from the cable tie that holds all the cables
                    together to the solar module which with precision generates
                    power for years to come. With time and experience we have
                    gained over the years, we have been able to set the right
                    technical and commercial balance in all the products we use
                    in our installations.
                  </p>
                </div>
                <Follow />
              </div>
            </div>
            <div className={styles.sidenav}>
              <Articles sidenav={true} show={1} />
            </div>
          </div>
        </div>
      
    </section>
  )
}
