import React from "react"
import Layout from "../layout"
import Title from "../smallTitle"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

// Styles
import styles from "../../styles/components/about/whySunterra.module.scss"

export default function WhySunterra() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "about1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 669, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section>
      <Layout>
        <div className={styles.wrapper}>
          <div className={styles.imgWrapper}>
            <Img
              fluid={data.file.childImageSharp.fluid}
              className={styles.img}
            />
          </div>
          <div className={styles.content}>
            <Title text="Why Sunterra?" />
            <p>
              Our solar systems are sleek and durable — silently converting
              sunlight to electricity for decades to come. They boast of
              outstanding performance in weak-light conditions as well as being
              able to withstand high temperatures and local conditions.
            </p>
            <p>
              Our mission is to provide the highest efficiency and lowest cost
              per watt of any solar provider within the country.
            </p>
            <p>
              We have helped countless homeowners harness solar energy to power
              their homes and also make money while doing so. As the cost per
              unit of energy from power grids continues to rise each year, many
              homeowners are seeking long-term alternatives to reduce their
              energy bills. Going solar is a great option - by producing your
              own electricity, you can save substantially and even earn money
              from the energy you produce.
            </p>
            <p>
              Our services include Customer Consultations, Site Survey, System
              Design, Finance Service, Grid Connection, Equipment Supply,
              Project Installation and After Sales Service.
            </p>
            <Link to="/contact">
              <button className={styles.btn}>Get In Touch</button>
            </Link>
          </div>
        </div>
      </Layout>
    </section>
  )
}
