import React from "react"

//Components
import SEO from "../components/seo"
import Header from "../components/navigation/header"
import Footer from "../components/footer"
import AboutHeader from "../components/about/header"
import WhySunterra from "../components/about/whySunterra"
import AboutSunterra from "../components/about/aboutSunterra"
import WindowDimensionsProvider from "../components/windowDimensionsProvider"

const IndexPage = () => (
  <WindowDimensionsProvider>
    <Header />
    <SEO
      title="About"
      article={false}
      pathname="/about"
      description="Our solar systems are sleek and durable — silently converting sunlight to electricity for decades to come."
    />
    <AboutHeader />
    <WhySunterra />
    <AboutSunterra />
    <Footer />
  </WindowDimensionsProvider>
)

export default IndexPage
